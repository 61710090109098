import { useTheme } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Custom Imports
import CustomToolTip from "../customToolTip/customToolTip";

const RenderStatusCell = ({doc_status, message}) => {
    const theme = useTheme();
    const is_in_progress = doc_status === 3 ? true : false;

    let color;
    switch (doc_status) {
        case 0: // Error
            color = theme.palette.status.error;
            break;
        case 1: // Initialized
            color = theme.palette.status.waiting;
            break;
        case 2: // Waiting
            color = theme.palette.status.waiting;
            break;
        case 3: // In Progress
          color = theme.palette.status.in_progress;
          break;
        case 4: // Finished
          color = theme.palette.status.success;
          break;
        default:
            color = theme.palette.status.waiting;
    }
     return (
          <div>
            <CustomToolTip title={message}>
              {!is_in_progress && <CircleIcon sx={{ fontSize: 20 }} style={{ color: color}}/>}
              {is_in_progress && <MoreHorizIcon sx={{ fontSize: 50 }} style={{ color: color}}/>}
            </CustomToolTip>
          </div>
        );
  };

export default RenderStatusCell;