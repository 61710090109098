import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import api from "../config/axiosConfigs";

function TokenValidationWrapper({ children }) {
    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const location = useLocation();

    useEffect(() => {
        // Function to check login status
        const checkLoginStatus = async () => {
          try {
            const response = await api.get('/verifytoken');
            console.log(response)
            if (response.status === 200) {
              setIsLoggedIn(true);
            } else {
              setIsLoggedIn(false);
            }
          } catch (error) {
            console.error('Error validating token:', error);
            setIsLoggedIn(false);
          }
          setLoading(false);
        };
        checkLoginStatus();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Or any loading spinner component
    }

    if (!isLoggedIn) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export default TokenValidationWrapper;
