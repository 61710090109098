import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import api from "../config/axiosConfigs";

// Import MUI Components
import { Alert, Backdrop, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";

// Import MUI Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from "@mui/icons-material/Visibility";

// Import Custom Components
import Dropzone from '../features/dropzone/Dropzone';
import './DocumentManagement.css';
import UploadWithAttributes from "../features/attributeManagement/UploadWithAttributes";
import DeleteConfirmation from "../features/deleteConfirmation/DeleteConfirmation";
import ErrorBanner from '../features/errorBanner/errorBanner';
import { PdfViewer } from "../features/PdfViewer";
import RenderStatusCell from "../features/renderStatusCell/RenderStatusCell";
import CustomToolTip from "../features/customToolTip/customToolTip";
import DetailDocumentPage from "../pages/DetailDocumentPage"

function DocumentManagement() {
  const maxFileSize = 19;
  const navigate = useNavigate();
  // Content of document list
  const [listContent, setListContent] = useState(null);

  // Upload handling
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [uploadSuccessfull, setUploadSuccessfull] = useState(null);
  const [currentItemUpload, setCurrentItemUpload] = useState({});
  const [isDocMetaDialogOpen, setDocMetaDialogOpen] = useState(false);

  // constants for handling the different document Actions
  const [showPDF, setShowPDF] = useState(); // VIEW: showPDF is set to the item_id of the currently shown item when one is selected, and set to false when none is shown
  const [urlPDF, setUrlPDF] = useState(); // VIEW: stores link for currently displayed PDF
  const [pdfViewerDisplay, setPdfViewerDisplay] = useState('none');
  const [currentItemDelete, setCurrentItemDelete] = useState(); // DELETE: currentItemDelete is set to the item_id and name of the item that is selected for deletion, or to false when none is selected
  const [editPopupOpen, setEditPopupOpen] = useState(false);  // EDIT
  const [editedDocument, setEditedDocument] = useState(null); // EDIT
  const [docUUID, setDocUUID] = useState(null); // EDIT

  const [error, setError] = useState(null);
  const [detailDocViewOpen, setDetailDocViewOpen] = useState(false);

  useEffect(() => {

    handleClickListDocuments();

  }, []);


  const handleClickEdit = (editedDocument) => {
    editedDocument.name = editedDocument.shortTitle;
    setEditedDocument(editedDocument);
    setEditPopupOpen(true);
  };

  const handleClickPushEdit = () => {
    console.log("editedDocument: ", editedDocument)
    api.post("docmgmtservice/updateDocument", editedDocument)
      .then(() => {
        handleClickListDocuments();
      }).catch((ex) => {
        setError("Failed to update document");
      });
    setEditPopupOpen(false);
  }

  const handleClickDetailedDocViewer = (item_uuid, editedDocument) => {
    console.log("handleClickDetailedDocViewer")
    console.log(editedDocument)
    setDocUUID(item_uuid);
    editedDocument.name = editedDocument.shortTitle;
    setEditedDocument(editedDocument);
    setDetailDocViewOpen(true);
  }

  // Handle click to update list of documents
  const handleClickListDocuments = () => {
    setIsUploadLoading(true)
    api.get("docmgmtservice/list_documents").then((response) => {
      let document = response.data.list_of_documents;
      setListContent(document)
      setIsUploadLoading(false)
      setUploadMessage(`Updated list.`)
      setUploadSuccessfull("success")
    }).catch((exeption) => {
      console.log(exeption)
      if (exeption.response?.status === 401) {
        navigate('/login', {
          state: {
            error: "Session expired, please login"
          }
        });
      } else {
        setUploadMessage(`Internal Application Error. Please try again later.`)
        setUploadSuccessfull("error")
      }
      setIsUploadLoading(false)
    });
  };

  // Handle click of "Delete" button
  const handleClickDelete = (event, id, name, uuid) => {
    setCurrentItemDelete({
      id: id,
      name: name,
      uuid: uuid
    })

  };

  // Handle click of confirmation in the "delete" confirmation window
  const handleClickConfirmDelete = (event) => {
    setCurrentItemDelete()
    setIsUploadLoading(true)
    console.log(currentItemDelete);

    let delete_item = {
      "uuid": currentItemDelete.uuid
    }

    api.post(
      "docmgmtservice/deleteItem", delete_item
    ).then((response) => {
      console.log("Delete call response:")
      console.log(response.data)
      setIsUploadLoading(false)
      setUploadMessage(`Successfully deleted file ${currentItemDelete.name}`)
      setUploadSuccessfull("success")
      handleClickListDocuments();
    }).catch((exeption) => {
      console.log(exeption)
      setUploadMessage(`Internal Application Error. Please try again later.`)
      setUploadSuccessfull("error")
      setIsUploadLoading(false)
    });
  };

  const handleClickCancelDelete = (e) => {
    setCurrentItemDelete()
  }

  // Handle click of confirmation in the "Upload" confirmation window
  const handleClickConfirmUpload = (event, file) => {
    setDocMetaDialogOpen(false);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('form_data', JSON.stringify(currentItemUpload));
    setIsUploadLoading(true)
    api.post('docmgmtservice/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }).then((response) => {
      setIsUploadLoading(false)
      setUploadMessage(`Successfully uploaded file ${file.name}`)
      setUploadSuccessfull('success')
      handleClickListDocuments();
    }).catch((exeption) => {
      console.log(exeption)
      setIsUploadLoading(false)
      setUploadMessage("Error uploading file.")
      setUploadSuccessfull("error")
    });
  };

  const handleClickCancelUpload = (e) => {
    setDocMetaDialogOpen(false)
    setCurrentItemUpload({})
  }

  const handleCloseDetailDocumentPage = () => {
    setDetailDocViewOpen(false);
    handleClickListDocuments();
  }

  const handleClickView = (e, item_id, item_name, item_uuid) => {
    console.log("handleClickView for " + item_id)

    // if the selected item is currently shown, hide it
    // if a new item is selected, switch to that one
    if (item_id === showPDF) {
      setShowPDF(false)
      return false
    }
    else {
      setShowPDF(item_id)
      let pdfItem = {
        "uuid": item_uuid
      }
      api.post("docmgmtservice/getPdfUrl", pdfItem).then((response) => {
        console.log("PDF response")
        console.log(response)
        let newList = listContent
        // Update table to store S3 URL in front-end so we don't need to call the API each time we want to show a PDF
        // TODO: actually use the table, only retrieve URL from back-end if it isn't stored in the listContent state yet
        for (const [key, value] of Object.entries(newList)) {
          if (value.id === item_id) {
            let newUrl = response.data.url
            newList[key].pdfUrl = newUrl

            console.log(newList[key])
            setUrlPDF(newUrl)
          }
        }
        console.log("Did not find ID in list.")
        // return false    
      }).catch((exeption) => {
        console.log(exeption)
        setUploadMessage(`Internal Application Error when attempting to view a document. Please try again later.`)
      });
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (file.size / (1024 * 1024) > maxFileSize) {
        setError("File is too big, maximum allowed is 20MB");
      }
      else {
        setCurrentItemUpload({
          name: file.name,
          file: file,
          fullTitle: file.name,
          tags: []
        })
        setDocMetaDialogOpen(true);
      }
    });
  }, []
  )

  // Definition of document management table structure
  const columns = [
    {
      field: "id",
      renderHeader: () => (
        <strong>
          {'ID'}
        </strong>
      ),
      width: 50
    },
    // {
    //   field: "documentTitle",
    //   renderHeader: () => (
    //     <strong>
    //       {'Document Name'}
    //     </strong>
    //   ),
    //   width: 150
    // },
    {
      field: "shortTitle",
      renderHeader: () => (
        <strong>
          {'Title'}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "left",
      minWidth: 300
    },
    {
      field: "authorFullName",
      renderHeader: () => (
        <strong>
          {'Author'}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "left"
    },
    // {
    //   field: "citableDate",
    //   renderHeader: () => (
    //     <strong>
    //       {'Date (Citations)'}
    //     </strong>
    //   ),
    //   type: "string",
    //   headerAlign: "center",
    //   align: "center"
    // },
    {
      field: "tags",
      renderHeader: () => (
        <strong>
          {'Tags'}
        </strong>
      ),
      editable: false,
      width: 200,
      renderCell: (params) => {
        const tagChips = params.value.map((tag, index) => (
          <Chip key={index} label={tag} style={{ marginRight: '5px' }} />
        ));
        return (
          <div>
            {tagChips}
          </div>
        );
      },
      headerAlign: "center"
    },
    {
      field: "timeOfUpload",
      renderHeader: () => (
        <strong>
          {"Time of Upload"}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 150
    },
    {
      field: "status_upload",
      renderHeader: () => (
        <strong>
          {"Upload"}
        </strong>
      ),
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <RenderStatusCell
        doc_status={params.row.status_upload}
        message={params.row.message_upload} />
    },
    {
      field: "status_metadata",
      renderHeader: () => (
        <strong>
          {"Metadata"}
        </strong>
      ),
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <RenderStatusCell
        doc_status={params.row.status_metadata}
        message={params.row.message_metadata} />

    },
    {
      field: "actions",
      renderHeader: () => (
        <strong>
          {'Actions'}
        </strong>
      ),
      editable: true,
      minWidth: 150,
      renderCell: (params) => {
        const item_id = params.row?.id
        const item_name = params.row?.shortTitle
        const item_uuid = params.row?.uuid
        return (
          <div>
            <CustomToolTip title="Delete" placement="bottom">
              <IconButton size="100px" sx={{
                color: 'gray', // Default color
                '&:hover': {
                  color: 'red', // Color on hover
                },
              }} onClick={(e) => handleClickDelete(e, item_id, item_name, item_uuid)}>
                <DeleteIcon />
              </IconButton>
            </CustomToolTip>
            {/* <IconButton>
              <DownloadIcon />
            </IconButton> */}
            <CustomToolTip title="View" placement="bottom">
              <IconButton sx={{ 
                color: (showPDF === params.row.id ? 'primary.main' : 'grey'),
                '&:hover': {
                  color: 'primary.main',
                },
                 }} onClick={(e) => handleClickView(e, item_id, item_name, item_uuid)}>
                <VisibilityIcon />
              </IconButton>
            </CustomToolTip>
            <CustomToolTip title="Edit" placement="bottom">
              <IconButton sx={{
                color: 'gray',
                '&:hover': {
                  color: 'primary.main',
                },
              }}>
                <EditIcon onClick={(e) => handleClickDetailedDocViewer(item_uuid, params.row)} />
              </IconButton>
            </CustomToolTip>
          </div>
        );
      },
      headerAlign: "center",
      align: "center"
    }
  ];

  return (
    <div>

      {/* Backdrop when document upload process is ongoing */}
      <Backdrop
        className="Doc-Management-Backdrop"
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploadLoading}
      >
        <div direction="column">
          <CircularProgress color="inherit" />
          <Typography>Loading</Typography>
        </div>
      </Backdrop>

      {/* Page Content */}

      <Stack
        className="Doc-Management-Stack-Row"
        direction="row"
        spacing={1}>

        <Box>
          <Stack
            className="Doc-Management-Stack-Column"
            direction="column"
            spacing={2}
          >

            <Dropzone onDrop={onDrop} accept={"image/*"} />

            {uploadSuccessfull && <Alert severity={uploadSuccessfull} mt={2}>{uploadMessage}</Alert>}

            <Box component="span"
              className="Doc-Management-Box-Table">
              <DataGrid
                rows={listContent ? (listContent) : ([])} // if no listContent was loaded yet: display "No rows"
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 }
                  }
                }}
                pageSizeOptions={[5, 10]}
                rowSelection={false}

              />
            </Box>
            <Button
              className="Doc-Management-Button-Update"
              sx={{
                iconSizeMedium: { "& > *:first-child": { fontSize: 22 } }
              }}
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={(e) => handleClickListDocuments(e)} >
              Update Table
            </Button>
          </Stack>
          {/* </div> */}
        </Box>
        {/* Right Side: PDF Viewer */}
        {showPDF &&
          <Box className="pdfViewer">
            <PdfViewer
              pdfUrl={urlPDF} selectedPage={1} setError={setError}
              setPdfViewerDisplay={setPdfViewerDisplay} setSelectedPdfUrl={setUrlPDF} />
          </Box>}

        {/* </Box> */}
        <div style={{ position: "absolute", left: "50%" }}>
          {editPopupOpen && <UploadWithAttributes
            handleClickCancelUpload={() => setEditPopupOpen(false)}
            handleClickConfirmUpload={handleClickPushEdit}
            document={editedDocument}
            setDocument={setEditedDocument}
            formTitle="Edit Document"
            buttonText="Save"
          />}
          {detailDocViewOpen && <DetailDocumentPage
            uuid={docUUID}
            document={editedDocument} setDocument={setEditedDocument}
            handleClose={() => handleCloseDetailDocumentPage()}
            handleClickPushEdit={handleClickPushEdit}
          />}
          {/* Delete Confirmation Dialogue */}
          {currentItemDelete && <DeleteConfirmation
            handleClickCancelDelete={handleClickCancelDelete}
            handleClickConfirmDelete={handleClickConfirmDelete}
            currentItemDelete={currentItemDelete}
            confirmationInformation={"Selected file: " + currentItemDelete.name} />}
          {/* Upload with Attributes Dialogue */}
          {isDocMetaDialogOpen && <UploadWithAttributes
            handleClickCancelUpload={handleClickCancelUpload}
            handleClickConfirmUpload={handleClickConfirmUpload}
            document={currentItemUpload}
            setDocument={setCurrentItemUpload}
          />}
        </div>
        {error && <ErrorBanner message={error} errorSetter={setError} />}
      </Stack>
    </div>

  )
}

export default DocumentManagement;
