const prod = {
    API_URL: process.env.API_ENDPOINT !== undefined ? process.env.API_ENDPOINT : "http://aml-api-development.d-roid.de/api",
    LLMS: [
       {
         label: "Azure OpenAI (GPT-3.5-Turbo-16k)",
         name: "azure_openai/gpt-3.5-turbo-16k"
       },
       {
        label: "Azure OpenAI (GPT-4)",
        name: "azure_openai/gpt-4"
      },
       {
         label: "Nemo (GPT20B)",
         name: "nemo/gpt20b"
       },
       {
         label: "Nemo (GPT-43B-001)",
         name: "nemo/gpt-43b-001"
       },
       {
         label: "Nemo (GPT-43B-002)",
         name: "nemo/gpt-43b-002"
       },
       {
         label: "Llama2 (7B)",
         name: "llama/7b"
       },
       {
         label: "Llama2 (13B)",
         name: "llama/13b"
       },
     ] 
   };

const dev = {
   API_URL: "http://localhost:8000/",
   LLMS: [
       {
         label: "Azure OpenAI (GPT-3.5-Turbo-16k)",
         name: "azure_openai/gpt-3.5-turbo-16k"
       },
       {
        label: "Azure OpenAI (GPT-4)",
        name: "azure_openai/gpt-4"
      },
       {
         label: "Nemo (GPT20B)",
         name: "nemo/gpt20b"
       },
       {
         label: "Nemo (GPT-43B-001)",
         name: "nemo/gpt-43b-001"
       },
       {
         label: "Nemo (GPT-43B-002)",
         name: "nemo/gpt-43b-002"
       },
       {
         label: "Llama2 (7B)",
         name: "llama/7b"
       },
       {
         label: "Llama2 (13B)",
         name: "llama/13b"
       },
     ] 
};

const THRESHOLD1_SemSim = 0.91
const THRESHOLD2_SemSim = 0.88

export const demo_mode = "FULL_APP" // options: "AML_ONLY", "FULL_APP", "GPS_DEMO"
export const menu_title = demo_mode === "AML_ONLY" ? "DocInsights" : "Menu"

export const config = process.env.NODE_ENV === "development" ? dev : prod;