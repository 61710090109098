import React, { useState, useEffect } from 'react';
import './LLMselectionSheet.css';
import Cookies from 'js-cookie';
import Typography from '@mui/material/Typography';

const LLMselectionSheet = ({ isOpen, onClose, onSave,  initialSettings }) => {
  const [settings, setSettings] = useState({
    Latency: 0,
    Response_quality: 0,
    Cost: 0,
    Explainability: 0,
  });

  useEffect(() => {
    const cookieSettings = Cookies.get('LLMSelectionSettings');
    if (cookieSettings) {
      setSettings(JSON.parse(cookieSettings));
    } else if (initialSettings && typeof initialSettings === 'object') {
      // Fallback to initialSettings if no cookie found
      setSettings({ ...settings, ...initialSettings });
    }
  }, [initialSettings]);

  const handleSliderChange = (settingName, value) => {
    const valueAsNumber = parseInt(value, 10);
    const currentTotal = Object.values(settings).reduce((a, b) => a + b, 0) - settings[settingName];
    const availableTokens = 10 - currentTotal;

    // If the new value does not exceed the available tokens, update as normal
    if (valueAsNumber <= availableTokens) {
        setSettings({ ...settings, [settingName]: valueAsNumber });
    } else {
        // If exceeding, set to maximum possible based on remaining tokens
        setSettings({ ...settings, [settingName]: availableTokens });
    }
  };

  const handleSave = () => {
    // Save settings to cookies
    Cookies.set('LLMSelectionSettings', JSON.stringify(settings), { expires: 90 }); // Expires in 7 days
    // Call parent component's onSave function
    onSave(settings);
  };

  if (!isOpen) return null;
  
  return (
    <div className="settings-backdrop">
      <div className="settings-sheet">
        <div className="settings-title">LLM Selection</div>
        <div className="settings-description">
        <Typography variant="body1">
          Distribute up to 10 tokens to set your preferences for the used Large Language Model (LLM).
        </Typography>
        {/* Distribute up to 10 tokens to set your preferences<br/>
        for the used Large Language Model (LLM). */}
        </div>
        {Object.entries(settings).map(([settingName, value]) => (
          <div key={settingName} className="setting-control">
            <div className="setting-title">{settingName}:</div>
            <input
              type="range"
              min="0"
              max="5"
              value={value}
              className="slider"
              onChange={(e) => handleSliderChange(settingName, e.target.value)}
              style={{ '--right-position': `${100 - (value / 5) * 100}%`}}
            />
            <div className="slider-scale">
              {[0, 1, 2, 3, 4, 5].map(mark => (
                <div key={mark} className="scale-mark">
                  <div className="scale-number">{mark}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="button-group">
          <button className="button close-button" onClick={onClose}>Close</button>
          <button className="button save-button" onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default LLMselectionSheet;
