import PersonIcon from '@mui/icons-material/Person';
import {
  Box, CircularProgress, Paper, Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { config } from "../config/config";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ErrorBanner from "../features/errorBanner/errorBanner";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import api from "../config/axiosConfigs";
import { EnhancedQueryBar } from '../features/queryBar/EnhancedQueryBar';

const ChatbotFormAssistant = () => {
  // From config get URL and possible LLM list
  const url = config.API_URL;
  const llms = config.LLMS;

  // Configuration Dialog stage and config values
  const [llm, setLLM] = useState(0);
  const [selectedLanguageSTT, setSelectedLanguageSTT] = useState('en-GB');
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);
  const [query, setQuery] = useState("");

  const [selectedTags, setSelectedTags] = useState([]);

  // Quick Check State Management
  const [quickCheckState, setQuickCheckState] = useState([]);
  const [isQuickCheckActive, setIsQuickCheckActive] = useState(false);
  const [goToFormFiller, setGoToFormFiller] = useState(false);

  // Creation of reference for chatbox component to implment automatically scrolling down 
  const chatbox = useRef(null)
  // Scroll to newest message if list of messages or attribute isAnswerLoading is updated 
  useEffect(() => chatbox.current.scrollIntoView({ behavior: "smooth", block: "end" }), [messages, isAnswerLoading])

  const handleQuickCheckResponse = (response_state_data) => {
      console.log("response_state_data: ")
      console.log(response_state_data)

      if (response_state_data.quick_check_state === undefined || response_state_data.quick_check_state === 0)
        {
          console.log("Error")
          // Todo Error handling
          setError("Empty response from LLM");
        } else {
          setQuickCheckState(response_state_data.quick_check_state)
        }

        if (response_state_data.is_quick_check_active === undefined || response_state_data.is_quick_check_active === 0)
        {
          console.log("Error")
          // Todo Error handling
          setError("Empty response from LLM");
        } else {
          setIsQuickCheckActive(response_state_data.is_quick_check_active)
        }

        if (response_state_data.go_to_form_filler === undefined || response_state_data.go_to_form_filler === 0)
        {
          console.log("Error")
          // Todo Error handling
          setError("Empty response from LLM");
        } else {
          setGoToFormFiller(response_state_data.go_to_form_filler)
        }
  }

  const callInitQuickCheck = () => {
    api.get(url + "monolith/getQuickCheckStateInitFormAssistant", {}).then((response) => {
      console.log("response: ")
      console.log(response)
      
      if (response.data.chat_assistant_response.state_data === undefined || response.data.chat_assistant_response.state_data === 0) {
        console.log("Error")
        // Todo Error handling
        setError("Empty response from LLM");
      } else {
        const response_state_data = response.data.chat_assistant_response.state_data
        handleQuickCheckResponse(response_state_data)
      }
    }).catch((exception) => {
    });
  }

  //Load the chat automatically when the page loads
  useEffect(() => {
    api.get(url + "monolith/getMessagesInitFormAssistant", {}).then((response) => {
        console.log("response: ")
        console.log(response)
        
        if (response.data.chat_assistant_response.messages === undefined || response.data.chat_assistant_response.messages === 0) {
          console.log("Error")
          // Todo Error handling
          setError("Empty response from LLM");
        } else {
          setMessages(response.data.chat_assistant_response.messages)
        }
    }).catch((exception) => {
    });

    callInitQuickCheck();

  }, []);

  const handleChangeLLM = (event) => {
    setLLM(event.target.value);
  };

  // Handle Reset History
  const handleResetHistory = (event) => {
    console.log("Handle Reset Form")

    api.get(url + "monolith/getMessagesInitFormAssistant", {}).then((response) => {
      console.log("response: ")
      console.log(response)
      setIsAnswerLoading(false);
      
      if (response.data.chat_assistant_response.messages === undefined || response.data.chat_assistant_response.messages === 0) {
        console.log("Error")
        // Todo Error handling
        setError("Empty response from LLM");
      } else {
        setMessages(response.data.chat_assistant_response.messages)
      }
    
    callInitQuickCheck();
  }).catch((exception) => {
  });
  };

  // Handle send new query to chatbot
  const handleQuery = (event, text, llm) => {
    setQuery("")
    if (messages === null && messages === undefined) {
      messages = [{ "role": "user", "content": text }];
    } else {
      messages.push({ "role": "user", "content": text })
    }

    // setIsAnswerLoading to true so laoding message is rendered 
    setIsAnswerLoading(true);
    const state_data = {
      "quick_check_state" : quickCheckState,
      "is_quick_check_active" : isQuickCheckActive,
      "go_to_form_filler" : goToFormFiller
    }

    console.log("STATE DATA:", state_data)

    const data = {
      "query": text,
      "llm": llms[llm].name,
      "messages": messages,
      "state_data" : state_data
    }

    api.post(url + "monolith/chatAIChatAssistant", data ).then((response) => {
      console.log("response: ")
      console.log(response)
      // setIsAnswerLoading to false so laoding message is NOT rendered anymore 
      setIsAnswerLoading(false);
      if (response.data.chat_assistant_response.messages === undefined || response.data.chat_assistant_response.messages === 0) {
        console.log("Error")
        // Todo Error handling
        setError("Empty response from LLM");
      } else {
        setMessages(response.data.chat_assistant_response.messages)
      }

      if (response.data.chat_assistant_response.state_data === undefined || response.data.chat_assistant_response.state_data === 0) {
        console.log("Error")
        // Todo Error handling
        setError("Empty response from LLM");
      } else {
        const response_state_data = response.data.chat_assistant_response.state_data
        handleQuickCheckResponse(response_state_data)
      }

    }).catch((exeption) => {
      // setIsAnswerLoading to false so laoding message is NOT rendered anymore
      setIsAnswerLoading(false);
      if (exeption.response.status === 400) {
        setError("Query was empty!");
      }
    });
  };

  const handleMicOn = (event) => {
    console.log("Mic on");
    console.log(selectedLanguageSTT)
    resetTranscript()
    SpeechRecognition.startListening({ continuous: true, language: selectedLanguageSTT })
  };

  const handleMicOff = (event) => {
    console.log("Mic off");
    SpeechRecognition.stopListening();
    console.log(transcript);
    setQuery(query + transcript)
    resetTranscript()
    console.log(transcript);
  };

  const handleChangeLanguage = (event) => {
    setSelectedLanguageSTT(event.target.value);
  };

  const handleOpenSettings = () => {
    SpeechRecognition.stopListening();
    resetTranscript()
    setIsSettingsDialogOpen(true);
  };

  const handleCloseSettingsDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsSettingsDialogOpen(false);
    }
  };


  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <Box sx={{
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      paddingX: '150px',
      paddingY: "60px",
      overflow: 'hidden'
    }}>
      <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }} >
        {messages && messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
        {isAnswerLoading && <PendingMessage></PendingMessage>}
        <div ref={chatbox} />
      </Box>
      <Box sx={{
        flexDirection: "column",
        alignItems: 'center',
        display: 'flex'
      }} >
        <EnhancedQueryBar
          query={query} setQuery={setQuery} handleQuery={handleQuery}
          listening={listening} handleMicOff={handleMicOff} handleMicOn={handleMicOn}
          transcript={transcript} resetTranscript={resetTranscript}
          isSettingsDialogOpen={isSettingsDialogOpen} handleOpenSettings={handleOpenSettings} handleCloseSettingsDialog={handleCloseSettingsDialog}
          handleChangeLanguage={handleChangeLanguage} selectedLanguageSTT={selectedLanguageSTT}
          llm={llm} handleChangeLLM={handleChangeLLM} handleResetHistory={handleResetHistory}
          selectedTags={selectedTags} setSelectedTags={setSelectedTags}
          enableTagSelection={false}/>
      </Box>
      <div>
        {error && <ErrorBanner message={error} errorSetter={setError} />}
      </div>
    </Box>
  );
};

const Message = ({ message }) => {
  const isSystem = message.role === "system";
  const isBot = message.role === "assistant";

  if (isSystem) {
    return (
      <div />
    )
  }

  const renderTextWithLineBreaks = (text) => {
    const lines = text.split('\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== lines.length - 1 && <br />} {/* Add <br> except for the last line */}
      </React.Fragment>
    ));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        mb: 2,
        alignItems: "center"
      }}
    >
      {isBot && <SupportAgentIcon sx={{ fontSize: "50px" }} size="large" color="primary" />}
      <Paper
        component="form"
        elevation={4}
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'center',
          margin: '20px',
          maxWidth: "70%",
          minHeight: "40px",
          borderRadius: 10,
          backgroundColor: isBot ? "primary.light" : "secondary.light"
        }}
      >
        <Typography color={isBot ? "#000000" : "#FFFFFF"} paddingRight="15px" paddingLeft="15px" align="left">
          {renderTextWithLineBreaks(message.content)}
        </Typography>
      </Paper>
      {!isBot && <PersonIcon sx={{ fontSize: "50px" }} size="large" color="secondary" />}
    </Box>
  );
};

const PendingMessage = ({ message }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        mb: 2,
        alignItems: "center"
      }}
    >
      <SupportAgentIcon sx={{ fontSize: "50px" }} size="large" color="primary" />
      <Paper
        component="form"
        elevation={4}
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'center',
          margin: '20px',
          maxWidth: "70%",
          minHeight: "40px",
          borderRadius: 10,
          backgroundColor: "primary.light"
        }}
      >
        <Typography
          color={"#000000"} paddingRight="15px" paddingLeft="15px" >Generating answer...</Typography>
        <CircularProgress color="secondary" />
      </Paper>
    </Box>
  );
};

export default ChatbotFormAssistant;
