import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, OutlinedInput, Select, Typography } from "@mui/material";
import MultiselectDropdown from "../documentSearchDropDown/dropdown";
import MultipleSelectChip from "../documentSearchDropDown/dropdownChat";
import React, { useState } from "react";
import TuneIcon from '@mui/icons-material/Tune';
import IconButton from "@mui/material/IconButton";
import LLMselectionSheet from '../LLMselectionSheet/LLMselectionSheet';
import { demo_mode } from "../../config/config";

function SettingsDialog({ open, handleClose, language, handleChange, llms, llm, handleChangeLLM, selectedTags, setSelectedTags, enableTagSelection=true}) {
    // Dialog for accessing settings like microphone control, LLM name, and tags
    const [enableDocumentSelection, setEnableDocumentSelection] = useState(false);
    const [selectedDocumentOption, setSelectedDocumentOption] = useState('value2');
    const [isLLLMselectionOpen, setIsLLMselectionOpen] = useState(false);

    const options = [
      // { value: 'value1', label: 'None' },
      { value: 'value2', label: 'All' },
      { value: 'value3', label: 'Subset' },
    ];
    
    const handleDocumentOptionChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedDocumentOption(selectedValue);
      setEnableDocumentSelection(selectedValue === 'value3');
      if (selectedValue !== 'value3') {
        setSelectedTags([])
      }
    };

    const toggleLLMselectionOpen = () => {
      setIsLLMselectionOpen(true);
    };
  
    const handleLLMselectionSheetsOnSave = (settings) => {
      console.log("Set LLM with params:", settings);
      console.log("Latency: ", settings.Latency);
      // TODO: We need to select the right model from the given settings. -> More Research needed
      // Find the index of "Azure OpenAI (GPT-3.5-Turbo-16k)"
      const azureIndex = llms.findIndex(llm => llm.label === "Azure OpenAI (GPT-3.5-Turbo-16k)");
      if (azureIndex !== -1) {
          handleChangeLLM({ target: { value: azureIndex.toString() } });
      }
      setIsLLMselectionOpen(false);
    }
  
    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {
              demo_mode !== "AML_ONLY" &&
              <div>
                {/* Not Used for AML yet */}
                {/* Select language for speech input */}
                <FormControl sx={{ m: 1, width: 400 }}>
                  <InputLabel>Speech-To-Text</InputLabel>
                  <Select
                    native
                    value={language}
                    onChange={handleChange}
                    input={<OutlinedInput label="Language" id="demo-dialog-native" />}
                  >
                    <option value={"en-GB"}>English</option>
                    <option value={"de-DE"}>German</option>
                  </Select>
                </FormControl>
              </div>
            }
            {/* Select LLM for query processing */}
            <div style={{ display: 'flex', justifyContent: "center" }}>

              <FormControl sx={{ m: 1, width: 400 }}>
                <InputLabel id="demo-simple-select-label">LLM</InputLabel>
                <Select
                    native
                    value={llm}
                    onChange={handleChangeLLM}
                    input={<OutlinedInput label="LLM" id="demo-dialog-native" />}
                >
                    {llms !== undefined && llms.map((llm, index) => (
                        <option value={index}>{llm.label}</option>
                    ))}
                </Select>
            </FormControl>
            <IconButton aria-label="Settings" color="primary" onClick={toggleLLMselectionOpen}>
              <TuneIcon />
            </IconButton>
            <LLMselectionSheet 
            isOpen={isLLLMselectionOpen} 
            onClose={() => setIsLLMselectionOpen(false)} 
            onSave={handleLLMselectionSheetsOnSave}
            initialSettings={{ Latency: 0, Response_quality: 0, Cost: 0, Explainability: 0 }}
            />
          </div>
          { enableTagSelection && <div>
            {/* Search in Documents */}
            Do you want to search in documents?
            <FormControl sx={{ m: 1, width: 400 }}>
            <InputLabel id="demo-simple-select-label">Documents</InputLabel>
              <Select
                  native
                  value={selectedDocumentOption}
                  onChange={handleDocumentOptionChange}
                  input={<OutlinedInput label="Documents" id="demo-dialog-native" />}
              >
                {options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            {/* Conditionally render MultiselectDropdown */}
            {enableDocumentSelection && (<MultipleSelectChip selectedTags = {selectedTags} setSelectedTags={setSelectedTags} />)}
          </div>}
          
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
}

export default SettingsDialog;