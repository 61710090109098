import React, { useState } from 'react';
import './tags.css';

function TagsInput({ tags, onTagsChange, placeholder="Type here to add more tags..." }) {
  const [inputValue, setInputValue] = useState('');

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleEnterKey();
    }
  }

  function handleEnterKey() {
    const value = inputValue.trim();
    if (value) {
      const newTags = [...tags, value];
      onTagsChange(newTags);
      setInputValue('');
    }
  }

  function removeTag(index) {
    const newTags = tags.filter((el, i) => i !== index);
    onTagsChange(newTags);
  }

  function handleInputChange(e) {
    setInputValue(e.target.value);
  }

  return (
    <div className="tags-input-container">
      {tags.map((tag, index) => (
        <div className="tag-item" key={index}>
          <span className="text">{tag}</span>
          <span className="close" onClick={() => removeTag(index)}>
            &times;
          </span>
        </div>
      ))}
      <input
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
        value={inputValue}
        type="text"
        className="tags-input"
        placeholder={placeholder}
      />
    </div>
  );
}

export default TagsInput;
