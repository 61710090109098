import React, { useState, useEffect } from "react";
import { Add, Delete } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DatasetIcon from '@mui/icons-material/Dataset';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SummarizeIcon from '@mui/icons-material/Summarize';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import {
  Box, Button, IconButton, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Tabs, Tab, TextField, Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import api from "../config/axiosConfigs";
import dayjs from 'dayjs';
// write me a new Const, that can display a summary of a document with nicely designed components
const DetailDocumentPage = ({ uuid, document, setDocument, handleClose, handleClickPushEdit }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderContent = () => {
    switch (value) {
      case 0:
        return <SummaryContent 
          document={document} setDocument={setDocument}/>;
      case 1:
        return <MetadataContent
          document={document} setDocument={setDocument}
          handleClickPushEdit={handleClickPushEdit} />;
      case 2:
        return <TagContent
          document={document}
          setDocument={setDocument} />;
      case 3:
        return <AbbreviationContent uuid={uuid} />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: "10%",
        left: "20%",
        width: "60%",
        height: "80%",
        backgroundColor: "white",
        boxShadow: 24,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
      }}
    >
      <Box sx={{ display: "flex", flex: "1 1 auto", overflow: "hidden" }}>
        <Box sx={{ flex: 5, p: 5, overflowY: "auto" }}>{renderContent()}</Box>
        <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            orientation="vertical"
            aria-label="icon label tabs example"
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            TabIndicatorProps={{
              sx: {
                left: 0,
                width: 3,
              },
            }}
          >
            <Tab icon={<SummarizeIcon />} label="Summary" />
            <Tab icon={<DatasetIcon />} label="Metadata" />
            <Tab icon={<LocalOfferIcon />} label="Tags" />
            <Tab icon={<VerticalSplitIcon />} label="Abbreviations" />
            <Tab
              icon={<CloseIcon sx={{ color: 'red' }} />}
              label="Close"
              onClick={handleClose}
            />
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};

const SummaryContent = ({ document, setDocument }) => {
  const [summaryState, setSummaryState] = useState(document.summary);

  const handleReset = () => {
    setSummaryState(document.summary);
  };

  const handleSave = () => {
    const updatedDocument = {
      ...document,
      summary: summaryState,
    };
    setDocument(updatedDocument);
    console.log("updatedDocument")
    console.log(updatedDocument)
    api.post("docmgmtservice/updateDocument", updatedDocument)
      .then(() => {
        console.log("Success");
      }).catch((ex) => {
        console.log("Error");
        // setError("Failed to update document");
      });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4">Summary</Typography>
        <Typography variant="body1">
          Please see or edit the summary for document: <strong>{document.fullTitle}</strong>.
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '75%', alignItems: 'center', justifyContent: 'flex-start', margin: '0 auto', flex: 1 }}>
        <TextField
          label="Summary"
          value={summaryState}
          onChange={(e) => {
            setSummaryState(e.target.value)}}
          variant="outlined"
          multiline
          sx={{ marginBottom: 5, width: '100%' }}
          inputProps={{ maxLength: 1250 }}
        />
        <Typography sx={{ color: 'grey', fontSize: 11 }}>Character Limit: 1250</Typography>
      </Box>
      <Box sx={{ padding: 2, backgroundColor: 'white', display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleSave} variant="contained" color="primary" sx={{ marginRight: 2 }}>
          Save
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleReset}>
          Reset
        </Button>
      </Box>
    </Box>
  );
};


const MetadataContent = ({ document, setDocument, handleClickPushEdit }) => {
  const [formState, setFormState] = useState({
    authorFullName: document.authorFullName,
    fullTitle: document.fullTitle,
    shortTitle: document.shortTitle || '',
    citableDate: document.citableDate ? dayjs(new Date(document.citableDate)) : null,
  });

  const handleInputChange = (field) => (event) => {
    setFormState({
      ...formState,
      [field]: event.target.value,
    });
  };

  const handleDateChange = (newDate) => {
    setFormState({
      ...formState,
      citableDate: newDate,
    });
  };

  const handleReset = () => {
    setFormState({
      authorFullName: document.authorFullName,
      fullTitle: document.fullTitle,
      shortTitle: document.shortTitle || '',
      citableDate: document.citableDate ? dayjs(new Date(document.citableDate)) : null,
    });
  };

  const handleSave = () => {
    const updatedDocument = {
      ...document,
      ...formState,
      citableDate: formState.citableDate ? new Date(formState.citableDate).toUTCString() : null,
    };
    setDocument(updatedDocument);
    console.log("updatedDocument")
    console.log(updatedDocument)
    api.post("docmgmtservice/updateDocument", updatedDocument)
      .then(() => {
        console.log("Success");
      }).catch((ex) => {
        console.log("Error");
        // setError("Failed to update document");
      });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4">Metadata</Typography>
        <Typography variant="body1">
          You are currently modifying the metadata of: {document.fullTitle}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '75%', alignItems: 'center', justifyContent: 'flex-start', margin: '0 auto', flex: 1 }}>
        <TextField
          label="Author Full Name"
          value={formState.authorFullName}
          onChange={handleInputChange('authorFullName')}
          variant="outlined"
          sx={{ marginBottom: 5, width: '100%' }}
        />
        <TextField
          label="Full Title"
          value={formState.fullTitle}
          onChange={handleInputChange('fullTitle')}
          variant="outlined"
          sx={{ marginBottom: 5, width: '100%' }}
        />
        <TextField
          label="Short Title"
          value={formState.shortTitle}
          onChange={handleInputChange('shortTitle')}
          variant="outlined"
          sx={{ marginBottom: 5, width: '100%' }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Citation Date"
            value={formState.citableDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ padding: 2, backgroundColor: 'white', display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleSave} variant="contained" color="primary" sx={{ marginRight: 2 }}>
          Save
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleReset}>
          Reset
        </Button>
      </Box>
    </Box>
  );
};
const TagContent = ({ document, setDocument }) => {
  const [editMode, setEditMode] = useState({ index: null, field: null });
  const [tags, setTags] = useState(document.tags);
  const [llmTags, setLLMTags] = useState(document.tags_llm);
  const [newTag, setNewTag] = useState('');
  const [tempTag, setTempTag] = useState('');

  useEffect(() => {
    // const sortedMockAbbreviations = mockAbbreviations.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
    // setAbbreviations(sortedMockAbbreviations);
    console.log(`Fetching tags for UUID: ${document.uuid}`);
    let chatItem = {
      "uuid": `${document.uuid}`
    }
    api.post('/docmgmtservice/getTagsFromDocID', chatItem)
      .then((response) => {
        console.log("Response received:", response.data);
        setLLMTags(response.data.tags.llm);
      })
      .catch((exception) => {
        console.error("Error fetching tags:", exception.response ? exception.response.data : exception.message);
        console.log("Error details:", exception);
      });
  }, [document.uuid]);

  const handleTagChange = (e) => {
    setTempTag(e.target.value);
  };

  const handleTagBlur = (index, source) => {
    if (source === 'USER') {
      const updatedTags = tags.map((tag, i) => (i === index ? tempTag : tag));
      setTags(updatedTags);
    } else {
      // Move the edited LLM tag to user tags
      const updatedLLMTags = llmTags.filter((_, i) => i !== index);
      setLLMTags(updatedLLMTags);
      setTags([...tags, tempTag]);
    }
    setEditMode({ index: null, field: null });
    setTempTag('');
  };

  const handleDeleteTag = (index, source) => {
    if (source === 'USER') {
      const updatedTags = tags.filter((_, i) => i !== index);
      setTags(updatedTags);
    } else {
      const updatedTagsLLM = llmTags.filter((_, i) => i !== index);
      setLLMTags(updatedTagsLLM);
    }
  };

  const handleAddTag = () => {
    if (newTag) {
      setTags([...tags, newTag]);
      setNewTag('');
    }
  };

  const handleSave = () => {
    const updatedDocument = { ...document, tags: tags, tags_llm: llmTags };
    setDocument(updatedDocument);
    console.log("updatedDocument")
    console.log(updatedDocument)
    api.post("docmgmtservice/updateDocument", updatedDocument)
      .then(() => {
        console.log("Success");
      }).catch((ex) => {
        console.log("Error");
      });
    console.log('Tags saved:', { userTags: tags, llmTags: llmTags });
  };

  const handleReset = () => {
    setTags(document.tags);
    setLLMTags(document.tags_llm);
  };

  const handleRegenerate = () => {
    console.log("Regenerate: ", document.uuid);
    api.post("docmgmtservice/generate_tags_from_uuid", { uuid: document.uuid })
      .then((response) => {
        console.log("Regenerated: ", response.data.tags)
        setLLMTags(response.data.tags);
      }).catch((ex) => {
        console.log("Error");
        console.error(ex);
      });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4">Tags</Typography>
        <Typography variant="body1">
          Overview of user-defined as well as LLM-generated tags.
        </Typography>
      </Box>
      <TableContainer component={Paper} sx={{ flex: 1, overflowY: 'auto', marginTop: 2 }}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: '4px 8px', typography: 'h6', position: 'sticky', top: 0, backgroundColor: 'background.paper', zIndex: 1 }}>Tag</TableCell>
              <TableCell sx={{ padding: '4px 8px', typography: 'h6', position: 'sticky', top: 0, backgroundColor: 'background.paper', zIndex: 1 }}>Source</TableCell>
              <TableCell sx={{ padding: '4px 8px', typography: 'h6', position: 'sticky', top: 0, backgroundColor: 'background.paper', zIndex: 1 }} align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tags.map((tag, index) => (
              <TableRow key={index}>
                <TableCell sx={{ padding: '0px 8px' }}>
                  {editMode.index === index && editMode.field === 'tag' ? (
                    <TextField
                      fullWidth
                      value={tempTag}
                      onChange={handleTagChange}
                      onBlur={() => handleTagBlur(index, 'USER')}
                      autoFocus
                    />
                  ) : (
                    <Typography
                      onClick={() => {
                        setEditMode({ index, field: 'tag' });
                        setTempTag(tag);
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      {tag}
                    </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '0px 8px' }}>
                  <Typography>USER</Typography>
                </TableCell>
                <TableCell sx={{ padding: '0px 8px' }} align="right">
                  <IconButton
                    onClick={() => handleDeleteTag(index, 'USER')}
                    sx={{
                      color: 'gray',
                      '&:hover': {
                        color: 'red',
                      },
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {llmTags.map((tag, index) => (
              <TableRow key={index + tags.length}>
                <TableCell sx={{ padding: '0px 8px' }}>
                  {editMode.index === index + tags.length && editMode.field === 'tag' ? (
                    <TextField
                      fullWidth
                      value={tempTag}
                      onChange={handleTagChange}
                      onBlur={() => handleTagBlur(index, 'LLM')}
                      autoFocus
                    />
                  ) : (
                    <Typography
                      onClick={() => {
                        setEditMode({ index: index + tags.length, field: 'tag' });
                        setTempTag(tag);
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      {tag}
                    </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '0px 8px' }}>
                  <Typography>LLM</Typography>
                </TableCell>
                <TableCell sx={{ padding: '0px 8px' }} align="right">
                  <IconButton
                    onClick={() => handleDeleteTag(index, 'LLM')}
                    sx={{
                      color: 'gray', // Default color
                      '&:hover': {
                        color: 'red',
                      },
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <TextField
                  fullWidth
                  label="New Tag"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                />
              </TableCell>
              <TableCell>
                <Typography>USER</Typography>
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={handleAddTag} color="primary">
                  <Add />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ padding: 2, backgroundColor: 'white', display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleSave} variant="contained" color="primary" sx={{ marginRight: 2 }}>
          Save
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleReset} sx={{ marginRight: 2 }}>
          Reset
        </Button>
        {/* <Button variant="outlined" color="secondary" onClick={handleRegenerate}>
          Regenerate
        </Button> */}
      </Box>
    </Box>
  );
};

const mockAbbreviations = [
  { id: '1', abbreviation: '', definition: 'Loading...' },
];
const AbbreviationContent = ({ uuid }) => {
  const [abbreviations, setAbbreviations] = useState([]);
  const [initialAbbreviations, setInitialAbbreviations] = useState([]); // New state variable
  const [newAbbreviation, setNewAbbreviation] = useState({ abbreviation: '', definition: '' });
  const [editMode, setEditMode] = useState({ id: null, field: null });

  useEffect(() => {
    const sortedMockAbbreviations = mockAbbreviations.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
    setAbbreviations(sortedMockAbbreviations);
    console.log(`Fetching abbreviations for UUID: ${uuid}`);
    let chatItem = {
      "uuid": `${uuid}`
    }
    api.post('/docmgmtservice/getAbbreviationsFromDocID', chatItem)
      .then((response) => {
        console.log("Response received:", response.data);
        const { abbreviations } = response.data;
        console.log(abbreviations);
        const formattedAbbreviations = abbreviations.map((abbr, index) => ({
          id: index.toString(),
          abbreviation: abbr.abbreviation,
          definition: abbr.meaning,
        }));
        setAbbreviations(formattedAbbreviations);
        setInitialAbbreviations(formattedAbbreviations); // Set the initial state
      })
      .catch((exception) => {
        console.error("Error fetching abbreviations:", exception.response ? exception.response.data : exception.message);
        console.log("Error details:", exception);
      });
  }, [uuid]);

  const handleAddAbbreviation = () => {
    if (newAbbreviation.abbreviation && newAbbreviation.definition) {
      setAbbreviations(prev => [
        ...prev,
        { ...newAbbreviation, id: Math.random().toString(36).substr(2, 9) }
      ].sort((a, b) => a.abbreviation.localeCompare(b.abbreviation)));
      setNewAbbreviation({ abbreviation: '', definition: '' });
    }
  };

  const handleDeleteAbbreviation = (id) => {
    setAbbreviations(prev => prev.filter(abbreviation => abbreviation.id !== id));
  };

  const handleChangeAbbreviation = (id, field, value) => {
    setAbbreviations(prev => prev.map(abbreviation =>
      abbreviation.id === id ? { ...abbreviation, [field]: value } : abbreviation
    ));
  };

  const handleRegenerate = () => {
    let chatItem = {
      "uuid": `${uuid}`
    }
    console.log("Regenerate Abbreviations: ", uuid);
    
    api.post("docmgmtservice/generate_abbreviations_for_uuid", chatItem)
      .then((response) => {
        console.log("Generated new abbrs")
        console.log(response.data)
        console.log(response.data.abbreviations)
        const abbreviations = response.data.abbreviations;
        console.log("Response generate abbr")
        console.log(abbreviations);
        const formattedAbbreviations = abbreviations.map((abbr, index) => ({
          id: index.toString(),
          abbreviation: abbr.abbreviation,
          definition: abbr.meaning,
        }));
        setAbbreviations(formattedAbbreviations);
        setInitialAbbreviations(formattedAbbreviations); // Set the initial state



        // console.log("Regenerated: ", response.data.abbreviations)
        // setAbbreviations(response.data.abbreviations);
      }).catch((ex) => {
        console.log("Error");
        console.error(ex);
      });
  };


  const handleSave = () => {
    let abbreviation_item = {
      "uuid": "" + uuid,
      "updated_abbreviations": abbreviations
    }
    console.log("abbreviation_item")
    console.log(abbreviation_item)
    api.post('/docmgmtservice/updateAbbreviations', abbreviation_item)
      .then(response => {
        console.log('Abbreviations saved successfully:', response.data);
        setInitialAbbreviations(abbreviations); // Update the initial state after saving
      })
      .catch(error => {
        console.error('Error saving abbreviations:', error);
      });
  };

  const handleReset = () => {
    setAbbreviations(initialAbbreviations); // Reset to initial state
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4">Abbreviations</Typography>
        <Typography variant="body1">
          View and edit automatically extracted abbreviations.
        </Typography>
      </Box>
      <TableContainer component={Paper} sx={{ flex: 1, overflowY: 'auto', marginTop: 2 }}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: '4px 8px', typography: 'h6', position: 'sticky', top: 0, backgroundColor: 'background.paper', zIndex: 1 }}>Abbreviation</TableCell>
              <TableCell sx={{ padding: '4px 8px', typography: 'h6', position: 'sticky', top: 0, backgroundColor: 'background.paper', zIndex: 1 }}>Definition</TableCell>
              <TableCell sx={{ padding: '4px 8px', typography: 'h6', position: 'sticky', top: 0, backgroundColor: 'background.paper', zIndex: 1 }} align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {abbreviations.map(({ id, abbreviation, definition }) => (
              <TableRow key={id}>
                <TableCell sx={{ padding: '0px 8px' }}>
                  {editMode.id === id && editMode.field === 'abbreviation' ? (
                    <TextField
                      fullWidth
                      value={abbreviation}
                      onChange={(e) => handleChangeAbbreviation(id, 'abbreviation', e.target.value)}
                      onBlur={() => setEditMode({ id: null, field: null })}
                      autoFocus
                    />
                  ) : (
                    <Typography
                      onClick={() => setEditMode({ id, field: 'abbreviation' })}
                      sx={{ cursor: 'pointer' }}
                    >
                      {abbreviation}
                    </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '0px 8px' }}>
                  {editMode.id === id && editMode.field === 'definition' ? (
                    <TextField
                      fullWidth
                      value={definition}
                      onChange={(e) => handleChangeAbbreviation(id, 'definition', e.target.value)}
                      onBlur={() => setEditMode({ id: null, field: null })}
                      autoFocus
                    />
                  ) : (
                    <Typography
                      onClick={() => setEditMode({ id, field: 'definition' })}
                      sx={{ cursor: 'pointer' }}
                    >
                      {definition}
                    </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '0px 8px' }} align="right">
                  <IconButton
                    onClick={() => handleDeleteAbbreviation(id)}
                    sx={{
                      color: 'gray',
                      '&:hover': {
                        color: 'red',
                      },
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <TextField
                  fullWidth
                  label="New Abbreviation"
                  value={newAbbreviation.abbreviation}
                  onChange={(e) => setNewAbbreviation({ ...newAbbreviation, abbreviation: e.target.value })}
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  label="New Definition"
                  value={newAbbreviation.definition}
                  onChange={(e) => setNewAbbreviation({ ...newAbbreviation, definition: e.target.value })}
                />
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={handleAddAbbreviation} color="primary">
                  <Add />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ padding: 2, backgroundColor: 'white', display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleSave} variant="contained" color="primary" sx={{ marginRight: 2 }}>
          Save
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleReset} sx={{ marginRight: 2 }}>
          Reset
        </Button>
        {/* <Button variant="outlined" color="secondary" onClick={handleRegenerate}>
          Regenerate
        </Button> */}
      </Box>
    </Box>
  );
};

export default DetailDocumentPage;