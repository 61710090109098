import React, { useState, useEffect } from 'react';
import { config } from "./../../config/config";
import Select from 'react-select';
import axios from 'axios';
import ErrorBanner from '../errorBanner/errorBanner';
import { messageStyles, customStyles } from './constants'
import Cookies from 'js-cookie';

function MultiselectDropdown({ tagsSetter }) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //Load the tags automatically when the page loads
    useEffect(() => {
        axios.get(config.API_URL + "docmgmtservice/get_queriable_tags", {

            headers: {
                Authorization: `Bearer ${Cookies.get('sessionToken')}`,
            },
            mode: 'cors'

        }).then(
            (response) => {
                console.log(response)
                let options = [];
                if (response && response.data.tags && Array.isArray(response.data.tags)) {
                    options = response.data.tags.map(tag => ({ value: tag, label: tag }));
                }
                if (options.length > 0) {
                    setLoading(false);
                    setOptions(options);
                }
                else {
                    setLoading(false);
                }
            }
        ).catch(() => {
            setError("Failed to load Tags! using the dafult tag only");
            setLoading(false);
        }
        );
    }, []);
    
    const handleChange = (selectedValues) => {
        setSelectedOptions(selectedValues);
        tagsSetter(
            selectedValues.map(item => item.value)
        );
    };

    // Render loading state
    if (loading) {
        return <p style={messageStyles.loading}>Loading Tags...</p>;
    }

    // Render error state
    if (error) {
        return <ErrorBanner message={error} errorSetter={setError} />
    }


    return (
        <div style={{ width: '200px', marginRight: '10px' }}>
            <Select
                options={options}
                value={selectedOptions}
                isMulti
                onChange={handleChange}
                styles={customStyles}
                placeholder="Select tags"
            />
        </div>
    );
}

export default MultiselectDropdown;
