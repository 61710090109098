import { ThemeProvider, } from '@emotion/react';
import ChatIcon from '@mui/icons-material/Chat';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Divider, SwipeableDrawer, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from '@mui/icons-material/AddBox';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import darkThemeDrawer from '../../theme/darkThemeDrawer';
import api from "../../config/axiosConfigs";

import ChatCreation from '../chatCreation/ChatCreation';
import DeleteConfirmation from "../deleteConfirmation/DeleteConfirmation";

const drawerWidth = 300;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: 20,
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const Drawer = styled(SwipeableDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  marginTop: "20px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

function HistoryDrawer(props) {
  const [chat, setChat] = useState({});
  const [newChatActive, setNewChatActive] = useState(false)
  const [deleteChatActive, setDeleteChatActive] = useState(false)
  const [editChatActive, setEditChatActive] = useState(false)
  const [menuItems, setMenuItems] = useState([]);

  const open = props.open
  const navigate = useNavigate();

  useEffect(() => {
    getChatItems();
  }, []);

  const openChatHistory = (key) => {
    navigate("/chat", { state: { chatId: key } });
  };

  // New Chat Creation
  const handleClickConfirmChatCreation = () => {
    let chatHistoryItem = {
      "chat_info" : chat
    }

    api.post('monolith/chatHistory/addChatHistoryRecord', chatHistoryItem 
    ).then((response) => {
      if (response.data.chatId === undefined) {
        // props.setError("No Id was generated");
        console.log("New Chat Creation failed.")
      } else {
        console.log(`Successfully created new chat ${chat.chatName} with ID ${response.data.chatId}`)
        const chatId = response.data.chatId
        openChatHistory(chatId)
      }

      getChatItems();
    }).catch((exception) => {
      // props.setError("No Id was generated");
      console.log("New Chat Creation failed, exception: ", exception)
    });

    setNewChatActive(false);
    setChat({})
  }

  const handleClickCancelChatCreation = () => {
    setNewChatActive(false);
    setChat({})
  }

  const handleNewChat = () => {
    setNewChatActive(true)
  }

  // Chat Deletion
  const handleDeleteChat = (key) => {
    setDeleteChatActive(key);
  }

  const handleDeleteChatCancel = (key) => {
    setDeleteChatActive(false);
  }

  const delete_chat = (key) => {
    console.log(deleteChatActive)
    let chatHistoryItem = {
      "chatId" : deleteChatActive
    }
    console.log("chatHistoryItem")
    console.log(chatHistoryItem)
    api.post('monolith/chatHistory/deleteChatHistoryRecord', chatHistoryItem
    ).then((response) => {
        getChatItems();
      })
      .catch((exception) => {
        console.log("Error: Response from chatHistory/deleteChatHistoryRecord: ", exception);
        // props.setError("Error loading chat, please reload page.");
      });
    
    setDeleteChatActive(false);
  };

  // Rename Chat
  const rename_chat = (key, text) => {
    setChat({ chatId: key, chatName: text })
    setEditChatActive(true)
  }

  const handleClickConfirmChatRenaming = () => {
    let chatHistoryRecord = {
      "chat_id" : chat.chatId,
      "new_chat_name" : chat.chatName
    }

    console.log("Updating chat...")
    console.log(chatHistoryRecord)

    api.post('monolith/chatHistory/updateChatHistoryRecordName', chatHistoryRecord
    ).then((response) => {
      getChatItems();
    }
    ).catch((exception) => {
      console.log("Error: Response from /updateChatHistoryRecordName: ", exception)
      // setError("Error loading chat, please reload page.");
    });

    setEditChatActive(false);
    setChat({})
  }

  const handleClickCancelChatRenaming = () => {
    setEditChatActive(false);
    setChat({})
  }

  const getChatItems = () => {
    api.get('monolith/chatHistory/getChatHistoryRecordsForUser')
      .then((response) => {
        console.log("response in getChatItems is: ", response);
        const chat_info = response.data.list_of_chats;
        // Create dynamic menu items from chat_info
        const dynamicMenuItems = chat_info.map((chat) =>
          renderMenuItem(String(chat.id), openChatHistory, String(chat.name), <ChatIcon />)
        );
        setMenuItems([dynamicMenuItems]);
      })
      .catch((exception) => {
        console.log("Error: Response from /monolith/getChatHistoryRecordsForUser: ", exception);
        // props.setError("Error loading chat, please reload page.");
      });
  };

  return (
    <div>
      {/* New chat creation dialog */}
      {newChatActive &&
        <ChatCreation
          handleClickCancelChatCreation={handleClickCancelChatCreation}
          handleClickConfirmChatCreation={handleClickConfirmChatCreation}
          chat={chat} setChat={setChat}>
        </ChatCreation>}

      {/* Chat deletion confirmation dialog */}
      {deleteChatActive && <DeleteConfirmation handleClickCancelDelete={handleDeleteChatCancel} handleClickConfirmDelete={delete_chat} confirmationTitle="Confirm Chat Deletion" confirmationQuestion='Do you really want to delete this chat? You cannot undo this.' confirmationButtonText='Delete Chat' paperClassName='delete-confirmation-history-drawer' />}

      {/* Rename chat dialog */}
      {editChatActive &&
        <ChatCreation
          handleClickCancelChatCreation={handleClickCancelChatRenaming}
          handleClickConfirmChatCreation={handleClickConfirmChatRenaming}
          chat={chat} setChat={setChat} formTitle={"Rename Chat"} buttonText={"Rename Chat"} subTitle={"Please choose a name for your chat."}>
        </ChatCreation>}

      {/* Drawer */}
      {open && (
        <ThemeProvider theme={darkThemeDrawer}>
          <Drawer variant="permanent" open={open} anchor="right">
            <DrawerHeader>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                px: 2.5,
                minHeight: 48,
                bgcolor: 'background.paper',
                flexDirection: 'row',
                justifyContent: 'flex-start'
              }}>
                <LightTooltip title="Close History" placement="left">
                  <IconButton aria-label="Close History" color="primary" onClick={props.handleHistoryDrawerClose} size="large">
                    <MenuIcon></MenuIcon>
                  </IconButton>
                </LightTooltip>
                <Typography sx={{ marginLeft: 2 }}>
                  <strong>Chat History</strong>
                </Typography>
              </Box>
            </DrawerHeader>
            <Divider color="white" />
            <List>
              <Button variant="contained" startIcon={<AddBoxIcon />} sx={{ my: "10px", mx: "10px", width: '85%' }} onClick={handleNewChat}>
                New Chat
              </Button>
              {menuItems}
            </List>
          </Drawer>
        </ThemeProvider>
      )}
    </div>
  );

  function renderMenuItem(key, handleOnClick, text, menuIcon) {

    return (
      <ListItem key={key} disablePadding sx={{ display: "flex", justifyContent: "space-between" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: "initial",
            px: 2.5
          }}
          onClick={() => { handleOnClick(key) }}
        >
          <ListItemText primary={text} sx={{ opacity: 1, marginLeft: '10px' }} />
        </ListItemButton>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => { handleDeleteChat(key) }}
          sx={{ marginRight: '20px' }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => { rename_chat(key, text) }}
          sx={{ marginRight: '20px' }}
        >
          <EditIcon />
        </IconButton>
      </ListItem>
    );
  }
}

export default HistoryDrawer;